<template>
  <div>
    <div v-if="isLoading" class="loading loading-big" style="margin-top: 25%"></div>
    <div v-else class="m-0 p-0">
    <VueBootstrap4Table 
      :classes="classes"
      :rows="list" 
      :columns="columns" 
      :config="config"
      :total-rows="listTotal"
      @on-change-query="onChangeQuery"
    >
      <template slot="empty-results">
        <div
          v-if="!isLoading"
          class="bold-text"
        >No records found</div>
        <div
          v-if="isLoading"
          class="row justify-content-center mt-3"
        ><div class="loading"></div></div>
      </template>
      <template slot="paginataion" slot-scope="props">
        {{props.currentPageRowsLength}} of {{props.originalRowsLength}}
      </template>
      <template slot="paginataion-previous-button"><i class="arrow my-auto left blur"></i></template>
      <template slot="paginataion-next-button"><i class="arrow my-auto right blur"></i></template>
    </VueBootstrap4Table>
    <div style="padding-bottom: 10rem"></div>
  </div>
  </div>
</template>
<script>
import * as vehicleActions from "../../store/modules/vehicle/actions"

export default {
  name: "VehicleManagement",
  components: {
    VueBootstrap4Table: () => import("@/components/ui/VueBootstrap4Table/VueBootstrap4Table"),
  },
  watch: {
  },
  computed: {
    sort: function(){
      return Object.assign({}, this.queryParams.sort[0]);
    },
  },
  data(){
    return{
      list: [],
      listTotal: 0,
      isLoading: false,
      columns: [
        // {
        //   label: "id",
        //   visibility: false,
        //   name: "id",
        //   sort: true,
        // },
        {
          label: "Vehicle No",
          name: "vehicleNo",
          column_text_alignment: "text-left",
          row_text_alignment: "text-left",
          sort: true,
        },
        {
          label: "Driver (New Shipment)",
          name: "driverNew",
          column_text_alignment: "text-left",
          row_text_alignment: "text-left",
          sort: true,
          width: "20%",
        },
        {
          label: "Driver (Current Shipment)",
          name: "driverCurrent",
          column_text_alignment: "text-left",
          row_text_alignment: "text-left",
          sort: true,
          width: "20%",
        },
        {
          label: "Driver (Last Shipment)",
          name: "driverLastUsed",
          column_text_alignment: "text-left",
          row_text_alignment: "text-left",
          sort: true,
          width: "20%",
        },
      ],
      config: {
        emitQuery: true,
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        page: 1,
        per_page: 10,
        preservePageOnDataChange: false,
        pagination: true,
        pagination_info: false,
        multi_column_sort: false,
        global_search: {
          visibility: true,
          case_sensitive: false,
          showClearButton: false,
          showSearchButton: false,
          searchOnPressEnter: false,
          placeholder: "Search Vehicle"
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1,
      },
      classes: {
        table: {
          "spci-table": true,
        }
      }
    }
  },
   methods: {
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      // this.getList();
    },
    getList() {
      this.isLoading = true;
      // const page = this.queryParams.page;
      // const limit = this.queryParams.per_page;
      // const search = this.queryParams.global_search;
      // let status;
      // const filter = {page: page, limit: limit, status: status, search: search, orderCol: orderCol, desc: desc};
      this.$store.dispatch(vehicleActions.VEHICLE_LIST_REQUEST).then(res => {
        this.list = res.response;
        this.listTotal = res.response.length;
      }).catch(err => {
        console.log(err)
      })
      .finally(() => this.isLoading = false)
    },
  },
  mounted(){
    this.getList();
  }
}
</script>